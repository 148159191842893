<style scoped>
  .content.chapter-manage{
    padding: 10px 0;
    padding-bottom: 90px;
  }
  .chaper-total{
    padding: 10px 15px 10px;
    /* background: #eee; */
  }
  .chaper-total span{
    margin-right: 30px;
    color: #666;
  }
  .chapter-list{
    /*background: #f2f2f2;*/
  }
  .chapter-box{
    padding: 10px 15px 5px;
    border-bottom: 1px solid #eee;
    font-size: 0.9rem;
    position: relative;
  }
  .chapter-box .chapter-del{
    position: absolute;
    top: 0;
    right: 0;
    width: 28px;
    height: 24px;
    background: url("../../assets/image/icon_del03.png") top right no-repeat;
    background-size: 100% 100%;
  }
  .chapter-box .chapter-box-info{
    margin-bottom: 15px;
  }
  .chapter-box .chapter-box-info .fr{
    margin-right: 15px;
  }
  .chapter-box .chapter-box-btn{
    text-align: right;
  }
  .chapter-box .chapter-box-btn label{
    float: left;
    position: relative;
    top: 4px;
    padding-left: 22px;
    color: #666;
    font-size: 0.7rem;
  }
  .chapter-box .chapter-box-btn label i{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 18px;
    height: 18px;
    background: url("../../assets/image/icon_type.png");
    background-size: 54px 18px;
  }
  .chapter-box .chapter-box-btn label i.icon_type01{
    background-position: 0 0;
  }
  .chapter-box .chapter-box-btn label i.icon_type02{
    background-position: -18px 0;
  }
  .chapter-box .chapter-box-btn label i.icon_type03{
    background-position: -36px 0;
  }
  .chapter-box .chapter-box-btn button{
    margin-left: 10px;
    background: transparent;
    line-height: 22px;
    height: 24px;
    padding: 0 5px;
    border: 1px solid #b5b5b5;
    border-radius: 5px;
    color: #666;
    font-size: 0.75rem;
  }
  .chapter-box .chapter-box-btn button:first-of-type{
    margin-left: 0;
  }
  .btn-bottom-box button:disabled{
    background: #9fa5f1;
    opacity: 1;
    color:rgba(255,255,255,0.7);
    box-shadow: 0px 0px 10px 3px rgba(199, 203, 254, 0.5);
  }
  .chapter-box .chapter-box-btn button img{
    height: 13px;
    vertical-align: middle;
    margin-right: 3px;
    position: relative;
    top: -1px;
  }
  .create-chapter-btn{
    position: fixed;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 10px 30px;
    /*background: #fff;*/
  }
  .create-chapter-btn a{
    display: inline-block;
    width: 50%;
    padding: 0 20px;
    -webkit-box-sizing: border-box;
  }
  .create-chapter-btn a .btn-bottom{
    height: 45px;
    line-height: 45px;
  }
  .manage-tablist ul{
    overflow: hidden;
    border-bottom: 1px solid #eee;
  }
  .manage-tablist ul li{
    float: left;
    width: 50%;
    font-size: 0.9rem;
    line-height: 40px;
    height: 40px;
    text-align: center;
  }
  .manage-tablist ul li a{
    display: inline-block;
    color: #000;
    position: relative;
  }
  .manage-tablist ul li.on a{
    color: #547CFF;
  }
  .manage-tablist ul li.on a::after{
    content: '';
    height: 2px;
    width: 30px;
    border-radius: 2px;
    background: #547CFF;
    position: absolute;
    bottom: 0px;
    left: calc(50% - 15px);
  }
  @media only screen and (max-width:340px){
    .chapter-box{
      padding: 10px 10px 5px;
    }
    .chapter-box .chapter-box-btn button{
      margin-left: 5px;
      padding: 0 3px;
    }
  }
  .chapter-box-info{
    overflow: hidden;
  }
  .chapter-box-info>span{
    display: inline-block;
    float: left;
  }
  .chapter-box-info>span:nth-of-type(1){
    display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
            /* width: 209px; */
            width: 180px;
  }
  .chapter-box-info .score{
    float: right;
    margin-right: 10px;
    color: #547CFF;
  }
  .chapter-box-info .score1{
    float: right;
    margin-right: 10px;
    color: #D90000!important;
  }
  .wordsnum {
      float: right!important;
  }
  .pagebgimg{
      position: fixed;
      top: 0;
      z-index: -1;
      width: 100%;height: 100%;
    }
    .pagebgimg>img{
      width: 100%;height: 100%;
    }
</style>
<template>
  <div>
    <header-title titleCon="章节管理" titleBtnStr="作品信息" @titleLeftBtn="titleFnReturn" @titleFnBtn="goCreateBook()"></header-title>
    <div class="content chapter-manage" style="padding-top: 0;">
      <div class="chaper-total">
        <span>章节数：{{bookInfo.chapterSize}}章</span>
        <span>文字数：{{bookInfo.sumWords}}</span>
      </div>
      <div class="manage-tabbox">
        <div class="manage-tablist">
          <ul>
            <li v-for="(item, index) in chapterTypeList" :key="index" :class="chapterType === index ? 'on': ''"><a @click="selectType(index)">{{item.text}}</a></li>
          </ul>
        </div>
        <div class="manage-tabconbox">
          <div class="manage-tabcon" v-if="chapterType === 0">
            <div class="chapter-list">
              <div class="chapter-box" v-for="(item, index) in chapterList" :key="index" :data-id="item.id">
                <div class="chapter-box-info">
                  <span>{{item.chapterTitle}}</span>
                  <span class="wordsnum fr">字数：{{item.chapterNumber}}</span>
                  <span class="score" v-if="item.score >= 60">{{item.score}}分</span>
                  <span class="score score1" v-if="item.score < 60&&item.score > 0">{{item.score}}分</span>
                  <span class="score score1" v-if="item.score == 0"></span>
                </div>
                <div class="chapter-box-btn">
                  <label v-if="item.showType === 0"><i class="icon_type01"></i>半身像</label>
                  <label v-if="item.showType === 1"><i class="icon_type02"></i>胸像</label>
                  <label v-if="item.showType === 2"><i class="icon_type03"></i>网聊</label>
                  <button v-if="item.ownFlag === 1 && item.auditStatus === 0" class="chapter-setcon" @click="setChapterCon(item.id)"><img src="../../assets/image/icon_con.png" height="18">内容创作</button>
                  <button v-else class="chapter-set" disabled><img src="../../assets/image/icon_con.png" height="18">内容创作</button>
                  <button v-if="item.ownFlag === 1 && item.auditStatus === 0" class="chapter-set" @click="setChapter(item.id)"><img src="../../assets/image/icon_set.png" height="18">章节设置</button>
                  <button v-else class="chapter-set" disabled><img src="../../assets/image/icon_set.png" height="18">章节设置</button>
                  <button @click="goLook(item.id,item.addUserId)"><img src="../../assets/image/icon_read.png" height="16">本章试读</button>
                  <a v-if="item.ownFlag === 1 && item.auditStatus === 0" class="chapter-del" @click="delChapter(item.id)"></a>
                </div>
              </div>
            </div>
          </div>
          <div class="manage-tabcon" v-if="chapterType === 1">
            <div class="chapter-list">
              <div class="chapter-box" v-for="(item, index) in chapterList" :key="index" :data-id="item.id">
                <div class="chapter-box-info">
                  <span>{{item.chapterTitle}}</span>
                  <span class="wordsnum fr">字数：{{item.chapterNumber}}</span>
                </div>
                <div class="chapter-box-btn">
                  <label v-if="item.showType === 0"><i class="icon_type01"></i>半身像</label>
                  <label v-if="item.showType === 1"><i class="icon_type02"></i>胸像</label>
                  <label v-if="item.showType === 2"><i class="icon_type03"></i>网聊</label>
                  <button v-if="item.ownFlag === 1 && item.auditStatus === 0" class="chapter-setcon" @click="setChapterCon(item.id)"><img src="../../assets/image/icon_con.png" height="18">内容创作</button>
                  <button v-else class="chapter-set" disabled><img src="../../assets/image/icon_con.png" height="18">内容创作</button>
                  <button v-if="item.ownFlag === 1 && item.auditStatus === 0" class="chapter-set" @click="setChapter(item.id)"><img src="../../assets/image/icon_set.png" height="18">章节设置</button>
                  <button v-else class="chapter-set" disabled><img src="../../assets/image/icon_set.png" height="18">章节设置</button>
                  <button @click="goLook(item.id,item.addUserId)"><img src="../../assets/image/icon_read.png" height="16">本章试读</button>
                  <a v-if="item.ownFlag === 1 && item.auditStatus === 0" class="chapter-del" @click="delChapter(item.id)"></a>
                  <!--<button v-if="bookInfo.isVerify === 0" class="chapter-set" @click="setChapter(item.id)"><img src="../../assets/image/icon_set.png" height="18">章节设置</button>-->
                  <!--<button v-else class="chapter-set" disabled><img src="../../assets/image/icon_set.png" height="18">章节设置</button>-->
                  <!--<button @click="goLook(item.id)"><img src="../../assets/image/icon_read.png" height="16">本章试读</button>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="create-chapter-btn btn-bottom-box">
        <a>
          <!-- <button v-if="bookInfo.isVerify === 0" class="btn-bottom" @click="createChapter">新建章节</button>
          <button v-else class="btn-bottom" disabled>新建章节</button> -->
          <button class="btn-bottom" @click="createChapter">新建章节</button>
          <!-- <button v-else class="btn-bottom" disabled>新建章节</button> -->
        </a>
        <a>
          <button class="btn-bottom" @click="bookLook">作品预览</button>
        </a>
      </div>
    </div>
    <base-dialog v-show="dialogShow" :dialogContent="dialogContent" @dialogCancel="dialogCancelFn" @dialogSure="dialogSureFn"></base-dialog>
    <bottom-dialog v-if="dialogBottomShow" @dialogCloseBottom="dialogCloseBottom" @dialogBottomBtn01="createDefultChapter" @dialogBottomBtn02="createSonChapter"></bottom-dialog>
    <div class="pagebgimg">
          <img :src="pagebg" alt="">
    </div>
  </div>
</template>
<script>
import headerTitle from '../../components/header/header-title-transparent.vue'
import baseDialog from '../../components/dialog/base-dialog.vue'
import bottomDialog from '../../components/dialog/bottom-dialog.vue'
export default {
  components: {
    headerTitle,
    baseDialog,
    bottomDialog
  },
  data () {
    return {
      windowHeight: document.documentElement.clientHeight,
      pagebg: require('../../assets/image/pagebgsm.png'),
      dialogShow: false,
      dialogBottomShow: false,
      chapterTypeList: [
        {
          text: '普通章节'
        },
        {
          text: '子剧情章节'
        }
      ],
      chapterType: 0,
      chapterList: [],
      chapterFirstId: '',
      bookInfo: {},
      dialogContent: '确定删除该章节吗？',
      delChapterId: ''
    }
  },
  computed: {
  },
  created () {
    // 适配背景图片
    if (this.windowHeight > 736) {
      this.pagebg = require('../../assets/image/pagebgbig.png')
    }
  },
  mounted () {
    // console.log(this.$route.query.chapterType)
    if (this.$route.query.chapterType === '1') {
      this.chapterType = 1
    } else {
      this.chapterType = 0
    }
    this.getChapterList(this.chapterType)
    this.clearInfo()
    // 解决加载bug，加载不完全问题
    if (location.href.indexOf('#reloaded') === -1) {
      location.href = location.href + '#reloaded'
      location.reload()
    }
  },
  methods: {
    // 返回
    titleFnReturn () {
      this.$router.push({ path: '/book' })
    },
    getChapterList (chapterType) {
      var that = this
      this.axios({
        method: 'get',
        url: '/v1/ugcChapterList',
        params: {
          ugcBookId: that.$route.query.bookId,
          chapterType: chapterType
        }
      }).then((res) => {
        if (res.data.head.flag === 0) {
          this.chapterList = res.data.content.chapterList
          this.bookInfo = res.data.content.ugcBook
        } else {
          this.$toast(res.data.head.desc)
        }
      })
    },
    selectType (index) {
      this.chapterType = index
      this.getChapterList(this.chapterType)
    },
    // 作品管理
    goCreateBook () {
      this.$router.push({ path: '/bookEdit', query: { bookId: this.bookInfo.id } })
    },
    // 内容创作
    setChapterCon (id) {
      this.$router.push({ path: '/chapterCon', query: { bookId: this.$route.query.bookId, chapterId: id, chapterType: this.chapterType.toString(), type: 'edit' } })
    },
    // 章节设置
    setChapter (id) {
      this.$router.push({ path: '/chapterAdd', query: { bookId: this.$route.query.bookId, chapterId: id, chapterType: this.chapterType.toString(), type: 'edit' } })
    },
    // 去试读
    goLook (id, sUserId) {
      // console.log(sUserId)
      this.$router.push({
        path: '/chapterLook',
        query: {
          bookId: this.$route.query.bookId,
          chapterId: id,
          lookType: 0,
          chapterType: this.chapterType.toString(),
          sUserId: sUserId
        }
      })
    },
    // 删除章节
    delChapter (id) {
      this.dialogShow = true
      this.delChapterId = id
    },
    dialogSureFn () {
      this.axios({
        method: 'get',
        url: '/v1/chapter/delete',
        params: {
          chapterId: this.delChapterId
        }
      }).then((res) => {
        if (res.data.head.flag === 0) {
          this.chapterList = res.data.content.chapterList
          this.dialogShow = false
        } else {
          this.$toast(res.data.head.desc)
        }
      })
    },
    dialogCancelFn () {
      this.dialogShow = false
    },
    dialogCloseBottom () {
      this.dialogBottomShow = false
    },
    createChapter () {
      this.dialogBottomShow = true
    },
    clearInfo () {
      localStorage.setItem('sltCptBgActive', '')
      localStorage.setItem('sltCptBgmActive', '')
      this.$store.state.sltCptBgActive = {
        firstTypeIndex: '',
        firstTypeId: '',
        subTypeIndex: '',
        subTypeId: '',
        listActive: ''
      }
      this.$store.state.sltCptBgmActive = {
        firstTypeIndex: '',
        firstTypeId: '',
        subTypeIndex: '',
        subTypeId: '',
        listActive: ''
      }
      this.$store.state.chapterInfo = {
        chapterName: '',
        chapterBg: {},
        chapterBgm: {},
        chapterCg: {},
        chapterCgIndex: -1,
        showType: -1,
        chapterType: -1,
        roleList: []
      }
    },
    // 新建章节
    createDefultChapter () {
      // this.clearInfo()
      this.$router.push({ path: '/chapterAdd', query: { bookId: this.bookInfo.id, chapterType: '0', type: 'add' } })
    },
    // 新建子章节
    createSonChapter () {
      this.$router.push({ path: '/chapterAdd', query: { bookId: this.bookInfo.id, chapterType: '1', type: 'add' } })
    },
    // 作品预览
    bookLook () {
      this.axios({
        method: 'get',
        url: '/v1/ugcChapterList',
        params: {
          ugcBookId: this.$route.query.bookId,
          chapterType: 0
        }
      }).then((res) => {
        if (res.data.head.flag === 0) {
          this.chapterFirstId = res.data.content.chapterList[0].id
          this.$router.push({
            path: '/chapterLook',
            query: { bookId: this.$route.query.bookId, chapterId: this.chapterFirstId, lookType: 1 }
          })
        } else {
          this.$toast(res.data.head.desc)
        }
      })
    }
  }
}
</script>
