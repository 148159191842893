<style scoped>
  .dialog-bottom-btn{
    text-align: center;
    font-size: 0.9rem;
  }
  .dialog-bottom-btn h3{
    color: #999;
  }
  .dialog-bottom-btn button,.dialog-bottom-btn h3{
    height: 60px;
    line-height: 60px;
  }
  .dialog-bottom-btn button{
    display: block;
    width: 100%;
    background: #fff;
    border-top: 1px solid #e5e5e5;
    color: #666;
    font-size: 0.9rem;
  }
  .dialog-bottom-btn button:hover{
    color: #656fef;
  }
</style>
<template>
  <div class="dialog-bg" @click="dialogBottomCloseFn">
    <div class="dialog-bottom-box">
      <div class="dialog-bottom-btn" @click.stop="dialogBottomCloseFn">
        <h3>请选择要创建的章节类型</h3>
        <div><button class="btn-sure" v-if="dialogData.btn[0]" @click="dialogBottomBtn01">{{dialogData.btn[0]}}</button></div>
        <div><button class="btn-sure" v-if="dialogData.btn[1]" @click="dialogBottomBtn02">{{dialogData.btn[1]}}</button></div>
        <div><button class="btn-cancel" v-if="dialogData.btn[2]" @click="dialogBottomCancel">{{dialogData.btn[2]}}</button></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dialogData: {
        btn: ['新建普通章节', '新建子剧情章节', '取消']
      }
    }
  },
  components: {
  },
  mounted () {},
  methods: {
    dialogBottomBtn01 () {
      this.$emit('dialogBottomBtn01')
    },
    dialogBottomBtn02 () {
      this.$emit('dialogBottomBtn02')
    },
    dialogBottomCancel () {
      this.$emit('dialogBottomCancel')
    },
    dialogBottomCloseFn () {
      this.$emit('dialogCloseBottom')
    }
  }
}
</script>
